import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { NavigationProvider, useNavigation } from './components/NavigationContext';

// Your page components
import HomePage from './components/HomePage';
import Chatbot from './components/Chatbot';
import AffirmationsAnimation from './components/AffirmationsAnimation';
import DetailedPage from './components/DetailedPage';
import AudioPlayer from './components/AudioPlayer';
import SuccessStage from './components/SuccessStage';
import Cancel from './components/Cancel';
import Terms from './components/Terms';
import PrivacyPolicy from './components/PrivacyPolicy';
import Disclaimer from './components/Disclaimer';

import './index.css';

function MainFlow() {
  /**
   * Main states
   */
  const [stage, setStage] = useState('home');
  const [affirmations, setAffirmations] = useState([]);
  const [audioUrl, setAudioUrl] = useState(null);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [contactId, setContactId] = useState(0);
  const [affirmationStartTime, setAffirmationStartTime] = useState(null);
  const [audioUrlReceived, setAudioUrlReceived] = useState(false);
  const [audioUrlObject, setAudioUrlObject] = useState(null);
  const [price, setPrice] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [chatSummary, setChatSummary] = useState('');
  const [previewId, setPreviewId] = useState(null);

  // If we haven't reloaded from session yet
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const location = useLocation();

  // Access the navigation context
  const { disableUnload, enableConfirmation, disableConfirmation } = useNavigation();

  // If user explicitly clicked "Buy Now" => skip the next confirm
  const isBuyNowNavigating = useRef(false);

  // Determine if we need to prompt (excluding home and success)
  const shouldPrompt = stage !== 'home' && stage !== 'success';

  // Memoize the beforeUnload handler so that its identity persists unless stage changes.
  const handleBeforeUnload = useCallback((e) => {
    // If on home or success, do nothing
    if (stage === 'home' || stage === 'success') return;
    e.preventDefault();
    // Note: modern browsers ignore the custom text.
    e.returnValue = 'Are you sure you want to leave this page? Your progress may be lost.';
    // Optionally, remove sessionStorage.clear() if that is causing unintended resets.
  }, [stage]);

  // Attach the beforeunload listener when appropriate.
  useEffect(() => {
    if (!disableUnload && shouldPrompt) {
      console.log('App.js: Attaching beforeunload event');
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      console.log(
        `App.js: Not attaching beforeunload (disableUnload=${disableUnload}, shouldPrompt=${shouldPrompt}, stage=${stage})`
      );
    }
    return () => {
      console.log('App.js: Removing beforeunload event');
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [disableUnload, shouldPrompt, stage, handleBeforeUnload]);

  const resetToHome = useCallback(() => {
    console.log('resetToHome: called');
    sessionStorage.clear();
    setStage('home');
    console.log('resetToHome: stage set to home');
    setAffirmations([]);
    setAudioUrl(null);
    setUserName('');
    setUserEmail('');
    setContactId(0);
    setAffirmationStartTime(null);
    setPreviewId(null);
    setAudioUrlReceived(false);
    setAudioUrlObject(null);
    history.push('/');
    console.log('resetToHome: navigated to /');
  }, [history]);

  // Clear local storage on homepage load
  useEffect(() => {
    if (stage === 'home') {
      console.log('Clearing local storage on homepage load');
      localStorage.clear();
    }
  }, [stage]);

  // History block to prompt on in‑app navigation.
  useEffect(() => {
    if (stage === 'home' || stage === 'success' || disableUnload) return;

    const unblock = history.block((tx) => {
      // If the "Buy Now" action was taken, skip the prompt.
      if (isBuyNowNavigating.current) {
        console.log('Buy Now navigation – skipping confirm');
        isBuyNowNavigating.current = false; // Reset the flag
        tx.retry(); // Proceed with the navigation immediately
        return;
      }

      // Otherwise, show confirmation dialog.
      const confirmLeave = window.confirm('Are you sure you want to leave this page? Your progress may be lost.');
      if (confirmLeave) {
        // If confirmed, reset to home:
        resetToHome();
      }
      // Do not call tx.retry() if user cancels => remain on current page
    });

    return () => {
      unblock();
    };
  }, [stage, disableUnload, history, resetToHome]);

  // Re-enable unload events on detailed stage
  useEffect(() => {
    if (stage === 'detailed') {
      console.log('App.js: Detailed stage active – re-enabling unload events');
      enableConfirmation();
    }
  }, [stage, enableConfirmation]);

  useEffect(() => {
    // 1) Parse the query param from the location
    const urlParams = new URLSearchParams(location.search);
    const modeParam = urlParams.get('mode') || 'live';

    console.log('Detected mode:', modeParam); // Debug log

    // 2) If "test" => skip normal session logic, set stage to "affirmations"
    //    with some test placeholders
    if (modeParam === "test") {
      console.log("Running in TEST mode => skipping session logic, going to affirmations");

      // Predefined example placeholders:
      setStage("affirmations");
      setAffirmations([
        "I am fully capable and ready to successfully run a marathon in the park.",
        "With each step I take, I am approaching my goal, feeling stronger and more confident.",
        "I am filled with an unshakable belief in my ability to complete the marathon with ease and joy.",
        "I visualize myself crossing the finish line, feeling overwhelmingly elated and proud of my achievement.",
        "My body is healthy, my mind is brimming with positivity, and my spirit is invigorated.",
        "Every training session brings me closer to performing my best at the marathon.",
        "I am surrounded by the beauty of the park, which energizes and inspires me to keep pushing forward.",
        "I trust my training and body to carry me through to the finish line of the marathon.",
        "I radiate happiness and gratitude as I run, appreciating every moment of the journey toward my goal.",
        "I am a marathon finisher, I see it, I feel it, and I know it within the depths of my soul."
      ]);
      setUserName("AK");
      setUserEmail("amit.kr.py@gmail.com");
      setContactId(262972458);
      setAffirmationStartTime(Date.now());
      setLoading(false);

      // Return here so we do not run the usual "savedStage" logic
      return;
    }

    // 3) If "live" => do normal parse session or previewId => stage
    const id = urlParams.get('previewId');
    if (id) {
      setPreviewId(id);
      setStage('detailed');
    } else {
      const savedStage = sessionStorage.getItem('currentStage');
      if (savedStage) {
        if (savedStage == 'audio') {
          setStage('home');
        } else {
        setStage(savedStage);
      }
      } else {
        setStage('home');
      }
    }
    setLoading(false);
  }, [location]);

  /**
   * “Buy Now” => skip confirm => external Stripe
   */
  const handleCheckoutClick = (priceOption) => {
    console.log(`handleCheckoutClick => ${priceOption}`);
    setPrice(priceOption);

    // Crucial: Disable the beforeunload event to prevent confirmation popup
    disableConfirmation();

    // Mark that navigation is intentional to skip history.block confirmation
    isBuyNowNavigating.current = true;
  };

  /**
   * 2) Save current data => always store
   */
  const saveCurrentState = useCallback(() => {
    sessionStorage.setItem('currentStage', stage);
    sessionStorage.setItem('affirmations', JSON.stringify(affirmations));
    sessionStorage.setItem('userName', userName);
    sessionStorage.setItem('userEmail', userEmail);
    sessionStorage.setItem('contactId', contactId.toString());
    if (affirmationStartTime) {
      sessionStorage.setItem('affirmationStartTime', affirmationStartTime.toString());
    }
    if (audioUrl) {
      sessionStorage.setItem('audioUrl', audioUrl);
    }
  }, [
    stage,
    affirmations,
    userName,
    userEmail,
    contactId,
    affirmationStartTime,
    audioUrl
  ]);

  /**
   * Chat => Affirmations
   */
  const handleChatComplete = (
    affirmations,
    newName,
    newEmail,
    newContactId,
    newChatHistory,
    newChatSummary
  ) => {
    setAffirmations(affirmations);
    setUserName(newName);
    setUserEmail(newEmail);
    setContactId(newContactId);
    setChatHistory(newChatHistory);
    setChatSummary(newChatSummary);
    sessionStorage.setItem('chatHistory', JSON.stringify(newChatHistory));
    sessionStorage.setItem('chatSummary', newChatSummary);
    setStage('affirmations');
    setAffirmationStartTime(Date.now());
    saveCurrentState();
  };

  /**
   * Affirmations => after ~30s => audio
   */
  useEffect(() => {
    let timer;

    if (stage === 'affirmations') {
      const check = () => {
        // 1) Re-check stage each iteration:
        //    If user already advanced to 'detailed', stop the timer logic
        if (stage === 'detailed') {
          return; // Exit early, do nothing more
        }

        // 2) Calculate elapsed time
        const elapsed = Date.now() - affirmationStartTime;

        // 3) If 30s have passed AND you have an audioUrlObject, transition to 'audio'
        if (elapsed >= 30000 && audioUrlObject) {
          setStage('audio');
          saveCurrentState();
        } else {
          // Otherwise, keep polling
          timer = setTimeout(check, 1000);
        }
      };

      // Start our initial check in 100ms
      timer = setTimeout(check, 100);
    }

    // Cleanup the timer on effect re-run or unmount
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [stage, affirmationStartTime, audioUrlObject, saveCurrentState]);

  /**
   * Audio => Detailed
   */
  const handleUnlockClick = () => {
    setStage('detailed');
    saveCurrentState();
  };

  /**
   * Affirmations => loaded audio => store
   */
  const handleAudioPathsReceived = useCallback((blob) => {
    setAudioUrl(blob);
    setAudioUrlReceived(true);
    if (audioUrlObject) {
      URL.revokeObjectURL(audioUrlObject);
    }
    const newUrlObj = URL.createObjectURL(blob);
    setAudioUrlObject(newUrlObj);
  }, [audioUrlObject]);

  useEffect(() => {
    return () => {
      if (audioUrlObject) {
        URL.revokeObjectURL(audioUrlObject);
      }
    };
  }, [audioUrlObject]);

  /**
   * If user returns from Stripe => success => stage=success
   */
  const handleSuccess = useCallback((custName, custEmail, cId) => {
    console.log('Stripe success =>', custName, custEmail, cId);
    setStage('success');
  }, []);

  /**
   * On mount => if ?session_id => success
   */
  useEffect(() => {
    // Parse the URL parameters
    const urlParams = new URLSearchParams(location.search);
    const sessionId = urlParams.get('session_id');
    if (sessionId) {
      // Remove the session_id from the URL immediately (using history.replace)
      urlParams.delete('session_id');
      history.replace({ search: urlParams.toString() });

      // Re-enable unload events and ensure we are in detailed stage
      isBuyNowNavigating.current = false;
      enableConfirmation();
      setStage('detailed');
      // (Optional: if needed, clear any old affirmation timer)
      // setAffirmationStartTime(null);

      // Now fetch success data from the backend
      fetch(`/api/success?session_id=${sessionId}`)
        .then((r) => r.json())
        .then((data) => {
          if (data.status === 'success') {
            handleSuccess(data.customer_name, data.customer_email, data.contact_id);
          } else {
            console.warn('Session ID found, but no success from backend:', data);
          }
        })
        .catch((err) => console.error(err));
    }
  }, [location.search, handleSuccess, history]);

  /**
   * home => chat
   */
  const handleChatStart = () => {
    setStage('chat');
    saveCurrentState();
  };

  /**
   * If user cancels => stage=cancel
   */
  const handleCancel = () => {
    setStage('cancel');
    saveCurrentState();
  };

  /**
   * FB Pixel
   */
  useEffect(() => {
    // Facebook Pixel tracking
    if (window.fbq) {
      switch (stage) {
        case 'home':
          window.fbq('trackCustom', 'Homepage', { content_name: 'Homepage' });
          break;
        case 'chat':
          window.fbq('trackCustom', 'Chatbot', {
            content_category: 'Chatbot',
            content_name: 'Start Chat',
          });
          break;
        case 'affirmations':
          window.fbq('trackCustom', 'AffirmationsPreview', {
            content_name: 'Affirmations Generated',
            num_items: affirmations.length,
          });
          break;
        case 'audio':
          window.fbq('trackCustom', 'AudioPreview', {
            content_name: 'Preview Audio',
            content_type: 'audio',
          });
          break;
        case 'detailed':
          window.fbq('trackCustom', 'SalesPage', {
            content_name: 'Sales Page',
            content_type: 'product',
          });
          break;
        case 'success':
          window.fbq('trackCustom', 'Purchase', {
            content_type: 'product',
            content_name: 'Download Page',
            value: price,
            currency: 'USD',
          });
          break;
        default:
          console.log('No FB Pixel event for stage=', stage);
      }
    }

    // TikTok Pixel tracking
    if (window.ttq) {
      switch (stage) {
        case 'home':
          window.ttq.track('Homepage', { content_name: 'Homepage' });
          break;
        case 'chat':
          window.ttq.track('Chatbot', { content_category: 'Chatbot', content_name: 'Start Chat' });
          break;
        case 'affirmations':
          window.ttq.track('AffirmationsPreview', { content_name: 'Affirmations Generated' });
          break;
        case 'audio':
          window.ttq.track('AudioPreview', { content_name: 'Preview Audio', content_type: 'product' });
          break;
        case 'detailed':
          window.ttq.track('SalesPage', { content_name: 'Sales Page', content_type: 'product' });
          break;
        case 'success':
          window.ttq.track('Purchase', { content_type: 'product', content_name: 'Download Page', value: price, currency: 'USD' });
          break;
        default:
          console.log('No TikTok Pixel event for stage=', stage);
      }
    }
  }, [stage, affirmations.length, price]);

  return (
    <div className="App">
      <Helmet>
        <link rel="icon" type="image/png" href="ML_icon_Violet.png" />
      </Helmet>
      <div className="app-container">
        {stage === 'home' && (
          <HomePage onClickStart={handleChatStart} onReset={resetToHome} />
        )}
        {stage === 'chat' && (
          <Chatbot onComplete={handleChatComplete} />
        )}
        {stage === 'affirmations' && (
          <AffirmationsAnimation
            affirmations={affirmations}
            userName={userName}
            userEmail={userEmail}
            waitTime={30000}
            onAudioUrlReceived={handleAudioPathsReceived}
            contactId={contactId}
            chatHistory={chatHistory}
            chatSummary={chatSummary}
          />
        )}
        {stage === 'audio' && (
          <AudioPlayer
            audioUrlObject={audioUrlObject}
            onUnlockClick={handleUnlockClick}
            userName={userName}
          />
        )}
        {stage === 'detailed' && (
          <DetailedPage
            onCheckoutClick={handleCheckoutClick}
            userName={userName}
            userEmail={userEmail}
            audioUrlObject={audioUrlObject}
            audioUrl={audioUrl}
            contactId={contactId}
          />
        )}
        {stage === 'cancel' && <Cancel />}
      </div>
    </div>
  );
}

export default function App() {
  return (
    <Router>
      <NavigationProvider>
        <Switch>
          <Route exact path="/" component={MainFlow} />
          <Route path="/success" component={SuccessStage} />
          {/* Public Policy Pages */}
          <Route path="/terms" component={Terms} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/disclaimer" component={Disclaimer} />
          {/* fallback 404 */}
          <Route render={() => <h1>404: Page Not Found</h1>} />
        </Switch>
      </NavigationProvider>
    </Router>
  );
}