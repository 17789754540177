import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './SuccessStage.css';
import DownloadButton from './DownloadButton';

const SuccessStage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const previewId = params.get('preview_id');
  const contactId = params.get('contact_id');
  const userEmail = params.get('user_email');
  const userName = params.get('user_name');
  const priceTag = params.get('product_tag');
  const [chatHistory, setChatHistory] = useState([]);
  const [chatSummary, setChatSummary] = useState('');

  console.log("Name:", userName, "Email:", userEmail, "Contact ID:", contactId, "Price:", priceTag, "Chat History:", chatHistory.current, "Chat Summary:", chatSummary.current);

  const [affirmations, setAffirmations] = useState([]);
  const [s3AudioUrl, setS3AudioUrl] = useState(null);
  const [jobId, setJobId] = useState(null);
  const [error, setError] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [isLoading, setIsLoading] = useState(true); // Track loading state

  // Use ref to track if the component is unmounted
  const isUnmounted = useRef(false);

  // Prevent navigating away during loading
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isLoading) {
        // Prevent refresh and back navigation when still in loading state
        event.preventDefault();
        event.returnValue = ''; // Required for some browsers to trigger the prompt
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isLoading]);

  // Fetch chat history and summary before any other operations
  useEffect(() => {
    if (!previewId || previewId === 'None') {
      setError("Preview ID is missing or invalid. Please contact support.");
      return;
    }

    const fetchPreviewData = async () => {
      try {
        const resp = await axios.get(`${backendUrl}/api/preview/${previewId}`);
        console.log("Preview Data Response:", resp.data);

        const { chatHistory, chatSummary } = resp.data;

        if (!chatHistory || !chatSummary) {
          throw new Error("Chat history or summary is missing.");
        }

        setChatHistory(chatHistory);
        setChatSummary(chatSummary);
      } catch (err) {
        console.error("Error fetching preview data:", err);
        setError("Failed to load chat history and summary. Please refresh the page or contact support.");
      }
    };

    fetchPreviewData();
  }, [previewId, backendUrl]);

  // If chat history or summary is not loaded, stop further processing
  useEffect(() => {
    if (error || !chatHistory || !chatSummary) {
      console.warn("Chat data not available. Halting further execution.");
      return;
    }
  }, [chatHistory, chatSummary, error]);

  // Load audio URL after chat data is loaded
  useEffect(() => {
    if (!chatHistory || !chatSummary || error) return;

    const storedAudioUrl = localStorage.getItem('s3AudioUrl');
    if (storedAudioUrl) {
      setS3AudioUrl(storedAudioUrl);
    } else if (!isGenerating) {
      setIsGenerating(true);
      getAffirmations();
    }
  }, [chatHistory, chatSummary, error, isGenerating]);

  useEffect(() => {
    if (s3AudioUrl) {
      localStorage.setItem('s3AudioUrl', s3AudioUrl);
    }
  }, [s3AudioUrl]);

  useEffect(() => {
    const storedEmailSent = localStorage.getItem('isEmailSent');
    if (storedEmailSent === 'true') {
      setIsEmailSent(true);
    }
  }, []);

  // Send email once s3AudioUrl is available and details are known, but before showing success state
  useEffect(() => {
    if (s3AudioUrl && userEmail && userName && priceTag && !isEmailSent) {
      console.log("Attempting to send email with:", { userName, userEmail, s3AudioUrl, priceTag });
      axios.post(`${backendUrl}/api/send_email`, {
        user_name: userName,
        user_email: userEmail,
        s3_url: s3AudioUrl,
        price_tag: priceTag
      })
      .then(response => {
        console.log("Email sent successfully:", response.data);
        setIsEmailSent(true);
        localStorage.setItem('isEmailSent', 'true');
      })
      .catch(error => {
        console.error("Error sending email:", error);
      });
    }
  }, [s3AudioUrl, userEmail, userName, priceTag, isEmailSent]);

  // Trigger FB and TT Pixel tracking on success
  useEffect(() => {
    if (s3AudioUrl && isEmailSent) {
      console.log("Tracking 'Purchase' event for FB and TT");

      // Facebook Pixel
      if (window.fbq) {
        window.fbq('track', 'Purchase', {
          content_name: 'Download Page',
        });
      }

      // TikTok Pixel
      if (window.ttq) {
        window.ttq.track('Purchase', {
          content_name: 'Download Page',
        });
      }
    }
  }, [s3AudioUrl, isEmailSent, priceTag]);

  // Helper function: Poll for the job status until it is complete.
  const pollJobStatus = async (jobId, resultType = "audio") => {
    const maxRetries = 60; // Maximum number of attempts
    const pollingInterval = 5000; // 5 seconds between each poll
    let attempts = 0;

    return new Promise((resolve, reject) => {
      const intervalId = setInterval(async () => {
        if (attempts >= maxRetries) {
          clearInterval(intervalId);
          reject(
            new Error(
              "Job took longer than expected. Please refresh the page or try again later."
            )
          );
          return;
        }

        try {
          const response = await axios.get(
            `${backendUrl}/api/task_status/${jobId}`
          );
          const data = response.data;

          if (data.status === "completed") {
            clearInterval(intervalId);
            if (resultType === "audio") {
              if (data.s3_url) {
                resolve(data.s3_url);
              } else if (data.result && data.result.s3_url) {
                resolve(data.result.s3_url);
              } else {
                reject(new Error("Audio URL not found in the result."));
              }
            } else if (resultType === "affirmations") {
              // Check for a top-level "affirmations" key or if the result is an array.
              if (data.affirmations) {
                resolve(data.affirmations);
              } else if (data.result && Array.isArray(data.result)) {
                resolve(data.result);
              } else {
                reject(new Error("Affirmations not found in the result."));
              }
            } else {
              // No specific type requested; return the full data
              resolve(data);
            }
          } else if (data.status === "failed") {
            clearInterval(intervalId);
            reject(new Error(data.error || "Job failed."));
          }
        } catch (error) {
          clearInterval(intervalId);
          reject(error);
        }

        attempts++;
      }, pollingInterval);

      // Optional: Return a cleanup function.
      return () => clearInterval(intervalId);
    });
  };

  const getAffirmations = async () => {
    try {
      const response = await axios.post(
        `${backendUrl}/api/affirmations_full`,
        {
          chat_summary: chatSummary,
          chat_history: chatHistory,
          user_name: userName,
          user_email: userEmail,
          contact_id: contactId,
          affirmations_count: 110
        },
        { timeout: 90000 }
      );

      const jobId = response.data.job_id;
      console.log("Affirmations job ID:", jobId);

      // Now wait for the generated affirmations.
      const generatedAffirmations = await pollJobStatus(jobId, "affirmations");
      console.log("Generated affirmations:", generatedAffirmations);
      setAffirmations(generatedAffirmations);

      // (Optionally) continue to fetch the audio URL using another call
      fetchAudioUrl(generatedAffirmations);
    } catch (error) {
      console.error("Error fetching affirmations:", error);
      setError("Failed to generate affirmations. Please try again.");
    }
  };

  const fetchAudioUrl = async (generatedAffirmations) => {
    try {
      const response = await axios.post(
        `${backendUrl}/api/generate_audio`,
        {
          affirmations: generatedAffirmations,
          user_name: userName,
          user_email: userEmail,
          context: 'full',
          contact_id: contactId,
          chat_history: chatHistory,
          chat_summary: chatSummary
        },
        {
          headers: { 'Content-Type': 'application/json' },
          responseType: 'json',
          timeout: 300000
        }
      );

      if (response.status === 202) {
        const jobId = response.data.job_id;
        console.log("Audio generation job ID:", jobId);

        const s3Url = await pollJobStatus(jobId, "audio");
        console.log("Generated audio URL:", s3Url);
        setS3AudioUrl(s3Url);
        localStorage.setItem("s3AudioUrl", s3Url);
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Error initiating audio generation:', error);
      setError('Failed to start audio generation. Please try again.');
    }
  };

  const LoadingState = () => (
    <div>
      <div className="logo">
        <img src="ML_Logo_Hor_White.png" alt="ManifestLab Logo" className="logo-img" />
      </div>
      <div className="container">
        <h1>Thanks For Your Order {userName}!</h1>
        <h2>Your Complete Manifestation Meditation Is Being Created...</h2>
        <p className="instructions">
          (Please do not close this page and wait 2-3 minutes to download your file)
        </p>
        <div className="loading-spinner"></div>
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );

  const SuccessState = () => (
    <div>
      <div className="logo">
        <img src="ML_Logo_Hor_White.png" alt="ManifestLab Logo" className="logo-img" />
      </div>
      <div className="container">
        <h1>Thanks For Your Order {userName}!</h1>
        <h2>Your Complete Manifestation Meditation Is Ready!</h2>
        <p className="instructions">
          (Click on the 'Download Audio' button below to get your meditation)
        </p>
        <DownloadButton s3AudioUrl={s3AudioUrl} />
        <p className="email-instructions">
          <b>Please check your email inbox</b> for your purchase receipt, as well as instructions on how to redeem additional credits (if you purchased a bundle).
        </p>
        <p className="contact">
          Still need help? Email contact@manifestlab.io
        </p>
      </div>
    </div>
  );

  // Now show the success state only after both conditions are met:
  return (s3AudioUrl && isEmailSent) ? <SuccessState /> : <LoadingState />;
};

export default SuccessStage;